import React from "react";
import loadable from '@loadable/component';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/source-sans-pro";

const Layout = loadable(() => import('../components/common/Layout'));
const Seo = loadable(() => import("../components/seo"));
const Manifest = loadable(() => import("../components/Manifest"));
const ContactForm = loadable(() => import("../components/ContactForm"));
const Team = loadable(() => import("../components/Team"));

const Info = () => (
  <Layout>
    <Seo title="Información" />
    <Manifest />
    <Team />
    <ContactForm />
 </Layout>
)

export default Info
